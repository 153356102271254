<template>
  <div>

    <div class="vx-card mt-5">
      <div class="vx-card__header">
        <div class="vx-card__title">
          <h4 class="">
            <img v-if="step.transit_details && step.transit_details.line" :src="step.transit_details.line.vehicle.icon" class="float-left"/>
            <div class="float-left ml-3">
              <span style="display: inline-block; margin-top: -5px;">{{step.html_instructions}}</span><br/>
              <small style="text-transform: uppercase" v-if="step.transit_details && step.transit_details.headsign">
                <vs-chip transparent color="success">
                  {{step.transit_details.headsign}}
                </vs-chip>

              </small>
            </div>
            <div class="clearfix"></div>
          </h4>
        </div>
      </div>
      <div class="vx-card__collapsible-content">
        <div class="vx-card__body">
          <!--                    {{routeInstructionsData}}-->






    <div class="flex mb-4">
      <div class="w-1/2">
        <vs-list>
          <!--                        <vs-list-item :title="routeInstructionsData.length.toSgetCenterOfPolylinetring() + ' vmesnih korakov'"-->
          <!--                                      subtitle="Število vmesnih korakov potovanja" v-if="routeInstructionsData.length > 1"></vs-list-item>-->
          <vs-list-item v-if="step.transit_details && step.transit_details.departure_time" :title="step.transit_details.departure_time.text" subtitle="Čas odhoda"></vs-list-item>
          <vs-list-item v-if="step.transit_details && step.transit_details.departure_stop && departureAddress" :title="step.transit_details.departure_stop.name + ' (' + departureAddress + ')'" subtitle="Vstopna postaja"></vs-list-item>
          <vs-list-item v-if="step.transit_details && step.transit_details.arrival_time" :title="step.transit_details.arrival_time.text" subtitle="Čas prihoda"></vs-list-item>
          <vs-list-item v-if="step.transit_details && step.transit_details.arrival_stop && arrivalAddress" :title="step.transit_details.arrival_stop.name + ' (' + arrivalAddress + ')'" subtitle="Izstopna postaja"></vs-list-item>
          <vs-list-item :title="step.duration.text" subtitle="Čas potovanja"></vs-list-item>
          <vs-list-item :title="step.distance.text" subtitle="Dolžina potovanja"></vs-list-item>
          <vs-list-item v-if="step.transit_details && step.transit_details.num_stops" :title="step.transit_details.num_stops.toString()" subtitle="Število vmesnih postaj"></vs-list-item>
          <vs-list-item v-if="step.transit_details && step.transit_details.line" :title="step.transit_details.line.agencies[0].name" subtitle="Izvajalec prevoza">
            <vs-button type="border" size="small" @click="() => { openWebsite(step.transit_details.line.agencies[0].url); }" class="">Spletna stran izvajalca prevoza</vs-button>
          </vs-list-item>
        </vs-list>
      </div>

      <div class="w-1/2">
        <gmap-map :center="getCenterOfPolyline(step.polyline.points)" v-bind:zoom="10" style="width: 100%; height:500px"
                  :staticmap="true">
          <gmap-polyline v-bind:path="getPolyline(step.polyline.points)"
                         v-bind:options="{ strokeColor:'#008000'}">
          </gmap-polyline>
        </gmap-map>

<!--        <vs-collapse accordion>-->
<!--          <vs-collapse-item>-->
<!--            <div slot="header">-->
<!--              <h6><b>Prikaži podatke iz Google Maps API odgovora</b></h6>-->
<!--            </div>-->
<!--            <vue-json-to-table :data="step" style=" white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"></vue-json-to-table>-->
<!--          </vs-collapse-item>-->
<!--        </vs-collapse>-->

      </div>

    </div>

    <div class="flex mt-5">
      <div class="w-full">
        <label class="vs-input--label">Opombe za uporabnika</label>
        <vs-textarea class="w-full" rows="6" v-model="step.notes"/>
      </div>
    </div>

        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Vue from 'vue';
import DirectionsRenderer from "../../../helpers/DirectionsRenderer";

let directionsService = null;
let directionsDisplay = null;
let geoCoder = null;

export default {
  name: 'IntermodalTransportSingleStep',
  components: {
    DirectionsRenderer,
  },
  props: {
    step: Object
  },
  data() {
    return {
      departureAddress: null,
      arrivalAddress: null
    }
  },
  watch: {
    step: {
      handler(val, oldVal) {
        const _this = this;
        if(val && val.transit_details && !_.isEqual(oldVal.transit_details,val.transit_details)) {
          _this.getAddress(val.transit_details.departure_stop.location, 'departure');
          _this.getAddress(val.transit_details.arrival_stop.location, 'arrival');
        }
      },
      deep: true
    },
  },
  mounted() {
    const _this = this;

    if(_this.step && _this.step.transit_details && _this.step.transit_details.departure_stop) {
      _this.getAddress(_this.step.transit_details.departure_stop.location, 'departure');
    }

    if(_this.step && _this.step.transit_details && _this.step.transit_details.arrival_stop) {
      _this.getAddress(_this.step.transit_details.arrival_stop.location, 'arrival');
    }
  },
  methods: {

    async getAddress(location, type) {
      const _this = this;

      geoCoder = new google.maps.Geocoder();
      await geoCoder.geocode({'location': location}, function (results, status) {
        if (status === 'OK') {
          if (results[0]) {
            console.log("TOLE", results[0]);
            if(type === 'departure') {
              _this.departureAddress = results[0].formatted_address;
            } else {
              _this.arrivalAddress = results[0].formatted_address;
            }

          } else {
            console.error('Geocode was not successful for the following reason: ' + status);
          }

        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      });

    },

    openWebsite(website) {
      window.open(website, '_blank');
    },

    getPolyline(path) {
      if (google && google.maps.geometry.encoding.decodePath(path)) {
        return google.maps.geometry.encoding.decodePath(path)
      }
    },

    getCenterOfPolyline(path) {
      let bounds = new google.maps.LatLngBounds();
      let i;


      let polygonCoords = google.maps.geometry.encoding.decodePath(path);

      for (i = 0; i < polygonCoords.length; i++) {
        bounds.extend(polygonCoords[i]);
      }

// The Center of the Bermuda Triangle - (25.3939245, -72.473816)
//       console.log(bounds.getCenter());
      return bounds.getCenter()
    },

  }
}
</script>

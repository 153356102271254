<template>
  <div id="intermodalReservationEditContainer">
    <IntermodalReservationEdit :reservationId="reservationId"></IntermodalReservationEdit>
  </div>
</template>

<script>
import Vue from 'vue';
import IntermodalReservationEdit from "../../components/Sopotniki/reservations/IntermodalReservationEdit";

export default {
  name: 'IntermodalReservation',

  components: {
    IntermodalReservationEdit
  },

  data() {
    return {
      reservationId: this.$route.params.reservationId ? parseInt(this.$route.params.reservationId) : null,
    }
  },

  computed: {},

  mounted() {
    const _this = this;
    // _this.getReservation();
  },

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    const _this = this;
    _this.reservationId = null;
    setTimeout(() => {next()}, 100);
  },

  methods: {
    // getReservation() {
    //   const _this = this;
    //
    //   _this.$vs.loading();
    // },

  }
}
</script>


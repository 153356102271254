<template>
  <div id="intermodalReservationEditContainer" class="relative">

    <div>

      <div class="sm:none md:flex-auto lg:flex xl:flex">

        <div class="sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
          <div class="sm:mr-0 md:mr-0 lg:mr-5 xl:mr-10">

            <div class="vx-row mb-2" v-if="customersData">
              <div class="vx-col w-full">
                <h1>
                  <span v-if="reservationData && reservationData.id">Uredi intermodalni prevoz</span>
                  <span v-if="reservationData && !reservationData.id">Dodaj intermodalni prevoz</span>
                  <div><small style="font-size: 60%;">Intermoadalni prevozi združujejo storitev Sopotniki z javnim
                    potniškim prometom</small></div>
                </h1>
              </div>
            </div>

            <div class="vx-row mb-2" v-if="customersData && reservationData">

              <div class="vx-col w-full">
                <label class="vs-input--label">
                  {{
                    reservationData.customer.gender === 'male' || !reservationData.customer_id ? 'Uporabnik' :
                      'Uporabnica'
                  }}
                </label>
                <v-select :filter="fuseSearch" :options="customersData" class="" v-model="customer"
                          :placeholder="reservationData.customer.gender === 'male' || !reservationData.customer_id ? 'Uporabnik' : 'Uporabnica'"
                          :getOptionLabel="option => option.first_name + '' + option.last_name"
                          @input="onCustomerChange">
                  <template v-slot:option="option">
                    <vs-list-item
                      :title="option.first_name + ' ' + option.last_name"
                      :subtitle="option.addresses.quick_search">
                      <template slot="avatar">
                        <vs-avatar class="mr-5" size="60px" :src="option.gender === 'male' ? sir : madam"
                                   :color="option.gender === 'male' ? '#dff8ff' : '#ffffdf'"/>
                      </template>
                    </vs-list-item>
                  </template>

                  <template v-slot:selected-option="option">
                    <vs-list-item
                      class="d-block"
                      :title="option.first_name + ' ' + option.last_name"
                      :subtitle="option.addresses.quick_search">
                      <template slot="avatar">
                        <vs-avatar class="mr-5" size="60px" :src="option.gender === 'male' ? sir : madam"
                                   :color="option.gender === 'male' ? '#dff8ff' : '#ffffdf'"/>
                      </template>
                    </vs-list-item>
                  </template>

                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                  </template>
                </v-select>

                <div class="clear clearfix"></div>


                <vs-alert
                  v-if="(customer && customer.notes)"
                  class="mt-5"
                  color="primary"
                  :title="'Opombe o ' + (customer.gender === 'male' ? 'uporabniku' : 'uporabnici')"
                  active="true">
                  {{ customer.notes }}
                </vs-alert>
              </div>
            </div>

            <div class="grid grid-cols-1 mt-5">
              <label for="gender" class="vs-input--label mb-2">Tip intermodalnega prevoz</label>

              <ul class="typePicker" id="typePicker">
                <li class="ml-1">
                  <vs-radio v-model="reservationData.type" vs-value="FROM_HOME_TO_DESTINATION" @change="onTypeChange">
                    prevoz od doma do destinacije
                  </vs-radio>
                </li>
                <li class="ml-10">
                  <vs-radio v-model="reservationData.type" vs-value="FROM_DESTINATION_TO_HOME" @change="onTypeChange">
                    prevoz od destinacije do doma
                  </vs-radio>
                </li>
              </ul>

            </div>


            <div v-if="reservationData">
              <div class="grid grid-cols-3 gap-2 mt-3">
                <div>
                  <label class="vs-input--label">Datum prevoza</label>
                  <datepicker placeholder="Datum prevoza" :language="slSI" :format="customDateFormatter"
                              :monday-first="true"
                              :disabled-dates="{
                                to: moment().subtract(1,'days').endOf('day').toDate(),
                                from: moment().startOf('day').add(7,'weeks').toDate(),
                              }"
                              @input="onDateChange" v-model="reservationData.departureTime"></datepicker>
                </div>
                <div v-if="reservationData.type === 'FROM_HOME_TO_DESTINATION'">
                  <label class="vs-input--label">Ura prihoda na destinacijo</label>
                  <flat-pickr :config="configTimePicker" v-model="arrivalTime" @on-change="getArrivalTime()"
                              placeholder="Ura prihoda na destinacijo"
                              class="w-full"/>
                </div>
                <div v-if="reservationData.type === 'FROM_DESTINATION_TO_HOME'">
                  <label class="vs-input--label">Ura odhoda iz destinacije</label>
                  <flat-pickr :config="configTimePicker" v-model="departureTime" @on-change="getDepartureTime()"
                              placeholder="Ura odhoda iz destinacije"
                              class="w-full"/>
                </div>
              </div>

              <div class="vx-card mt-5">
                <div class="vx-card__header">
                  <div class="vx-card__title">
                    <h4 class="">
                      <span class="">Končna destinacija</span></h4>
                    <div class="clearfix"></div>

                  </div>
                </div>
                <div class="vx-card__collapsible-content">
                  <div class="vx-card__body">

                    <div class="grid grid-cols-1">
                      <label for="departureAddress" class="vs-input--label">Naslov končne destinacije</label>

                      <gmap-autocomplete
                        ref="finalDestinationAddress"
                        id="finalDestinationAddress"
                        @place_changed="(data) => { getAddressData(data, 'finalDestination') }"
                        placeholder="začni vpisovati, nato izberi naslov iz ponujenih možnosti"
                        :value="finalDestination.quickSearch"
                        :options="{componentRestrictions: { country: 'si' }}"
                        :select-first-on-enter="true"
                        class="vs-inputx vs-input--input normal"></gmap-autocomplete>
                    </div>

                    <div class="grid grid-cols-1 mt-5">
                      <vs-input class="w-full" label="Naziv končne destinacije (ime naselja, vasi oz. ustanove)"
                                v-model="finalDestination.addressName"/>
                    </div>

                    <vs-collapse ref="collapsible" class="mt-5">

                      <vs-collapse-item class="addressCollapse">
                        <div slot="header" class="p-0 text-primary">
                          Podrobnosti naslova končne destinacije
                        </div>

                        <div class="collapseBody mt-5">

                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Ulica" v-model="finalDestination.route"/>
                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Hišna številka"
                                        v-model="finalDestination.streetNumber"/>
                            </div>
                          </div>
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Naziv pošte"
                                        v-model="finalDestination.postalTown"/>
                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Poštna številka"
                                        v-model="finalDestination.postalCode"/>
                            </div>
                          </div>
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Občina" v-model="finalDestination.municipality"/>
                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Država" v-model="finalDestination.country"/>
                            </div>
                          </div>
                        </div>
                      </vs-collapse-item>
                    </vs-collapse>
                  </div>
                </div>

              </div>

              <div class="flex mt-5">
                <div class="w-full">
                  <label class="vs-input--label">Splošne opombe</label>
                  <vs-textarea class="w-full" rows="6" v-model="reservationData.notes"/>
                </div>
              </div>

              <!--              {{departureStop}}<br/><br/>-->
              <!--              {{arrivalStop}}-->
              <!--              <hr/>-->

              <div v-if="routeInstructionsData" class="mt-20">
                <h4 class="">Načrt poti z javnim potniškim prometom</h4>

                <div v-for="(step, stepIndex) in routeInstructionsData"
                     :key="'selectedStepItem_' + stepIndex" class="mb-10">

                  <IntermodalTransportSingleStep v-if="step" :step="step"
                                                 :key="'step_' + stepIndex"></IntermodalTransportSingleStep>

                </div>
              </div>

              <div v-if="routeInstructionsData" class="mt-20">
                <h4 class="">
                  Uporaba taksi storitve
                </h4>

                <div class="vx-card mt-5">
                  <div class="vx-card__header">
                    <div class="vx-card__title">
                      <h4 class="">
                        <img :src="'//maps.gstatic.com/mapfiles/transit/iw2/6/taxi.png'" class="float-left"/>
                        <div class="float-left ml-3">
                          <span style="display: inline-block; margin-top: -5px;">Taksi prevoz</span><br/>
                        </div>
                        <div class="clearfix"></div>
                      </h4>
                    </div>
                  </div>
                  <div class="vx-card__collapsible-content">
                    <div class="vx-card__body">

                      <div class="grid grid-cols-1 mt-5">
                        <label class="vs-input--label mb-2">
                    <span v-if="reservationData.type === 'FROM_HOME_TO_DESTINATION'">
                      Uporaba taksi prevoza od postajališča JPP do končne destinacije
                    </span>
                          <span v-if="reservationData.type === 'FROM_DESTINATION_TO_HOME'">
                      Uporaba taksi prevoza od končne destinacije do postajališča JPP
                    </span>
                        </label>

                        <ul class="taxiPicker" id="taxiPicker">
                          <li class="ml-1" @click.stop="onRequiresTaxiChange('true')">
                            <vs-radio v-model="reservationData.requireTaxi" :vs-value="'true'">
                              DA
                            </vs-radio>
                          </li>
                          <li class="ml-10" @click.stop="onRequiresTaxiChange('false')">
                            <vs-radio v-model="reservationData.requireTaxi" :vs-value="'false'">
                              NE
                            </vs-radio>
                          </li>
                        </ul>
                      </div>

                      <div class="flex mt-5" v-if="reservationData.requireTaxi === 'true'">
                        <div class="w-full">
                          <label class="vs-input--label">Opombe glede taksi prevoza</label>
                          <vs-textarea class="w-full" rows="6" v-model="reservationData.taxiNotes"/>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


              </div>


              <!--              <vue-json-to-table v-if="routeInstructionsData" :data="{routeInstructionsData}" style=" white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"></vue-json-to-table>-->

            </div>

            <div class="flex mt-10 mb-20">
              <div class="w-1/2">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
                  <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2">Prekliči</vs-button>
                </vs-col>
              </div>
              <div class="w-1/2">
                <div class="flex">
                  <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" v-if="reservationData.id">
                    <vs-button size="large" color="danger" @click="beforeDeleteReservation" icon="delete">Izbriši
                    </vs-button>
                  </vs-col>

                  <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" v-if="!reservationId">
                    <vs-button size="large" color="success" icon="save" @click="saveReservation">Shrani</vs-button>
                  </vs-col>

                  <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" v-if="reservationId">
                    <vs-button size="large" color="success" icon="save" @click="updateReservation">Shrani spremembe
                    </vs-button>
                  </vs-col>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
          <template>

            <div class="vx-card mt-16" style="overflow: hidden">
              <div class="vx-card__header">
                <h4 class="text-primary mb-4">Intermodalnost</h4>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body p-6">

<!--                  {{reservationData.departure}}-->
<!--                  {{reservationData.destination}}-->

<!--                  selectedDepartureTime: {{selectedDepartureTime}}-->
<!--                  selectedArrivalTime: {{selectedArrivalTime}}-->

                  <IntermodalTransport
                    :key="reservationData.departure + '_' + reservationData.destination + '_' + selectedDepartureTime + '_' + selectedArrivalTime"
                    :origin="reservationData.departure"
                    :destination="reservationData.destination"
                    :departureTime="selectedDepartureTime"
                    :arrivalTime="selectedArrivalTime"
                    @routeInstructions="routeInstructions"
                    @departureStop="(val) => { departureStop = {};  departureStop = val; }"
                    @arrivalStop="(val) => { arrivalStop = {}; arrivalStop = val; }"
                  ></IntermodalTransport>
                </div>
              </div>
            </div>


          </template>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import DirectionsRenderer from "../../../helpers/DirectionsRenderer";
import IntermodalTransport from "@/components/Sopotniki/reservations/IntermodalTransport";
import IntermodalTransportSingleStep from "@/components/Sopotniki/reservations/IntermodalTransportSingleStep";
import vSelect from "vue-select";
import Fuse from 'fuse.js'
import Datepicker from 'vuejs-datepicker';
import {slSI} from 'vuejs-datepicker/dist/locale';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import sir from '../../../assets/images/portrait/sir.svg';
import madam from '../../../assets/images/portrait/madam.svg';
import {minLength, required} from "vuelidate/lib/validators";

let directionsService = null;
let directionsDisplay = null;
let geoCoder = null;

export default {
  name: 'reservation',

  components: {
    IntermodalTransport,
    IntermodalTransportSingleStep,
    DirectionsRenderer,
    'v-select': vSelect,
    'datepicker': Datepicker,
    'flat-pickr': flatPickr,
  },
  props: {
    reservationId: Number
  },
  data() {
    return {
      sir: sir,
      madam: madam,
      slSI: slSI,
      dynamicIndex: this.moment().valueOf(),

      // reservationData: null,
      routeDistanceAB: null,
      routeDistanceBC: null,
      routeDistanceTotal: null,
      routeTravelTimeAB: null,
      routeTravelTimeBC: null,
      routeTravelTimeTotal: null,
      center: {lat: 46.0569, lng: 14.5058},
      infoContent: '',
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {width: 0, height: -35}
      },

      customersData: null,
      customer: null,

      volunteersData: null,
      volunteer: null,

      reservationPurposesData: null,
      reservationPurpose: null,

      carsData: this.$globalFunctions.getCurrentUnit().cars,
      car: null,

      date: new Date(),
      departureTime: this.moment().hours('08').minutes('0').valueOf(),
      arrivalTime: this.moment().hours('10').minutes('0').valueOf(),

      configTimePicker: {
        enableTime: true,
        noCalendar: true,
        allowInput: true,
        altFormat: 'H:i',
        altInput: true,
        time_24hr: true,
        dateFormat: 'H:i',
        minuteIncrement: 15
      },

      reservationData: {
        "id": null,
        "type": "FROM_HOME_TO_DESTINATION",
        "disabled": null,
        "deleted": null,
        "customer_id": null,
        "unit_id": null,
        "departureTime": null,
        "departure_address_id": null,
        "destination": {},
        "arrivalTime": null,
        "car_id": null,
        "notes": null,
        "route_distance": null,
        "route_time": null,
        "route_cost": null,
        "agreed_donation": null,
        "created_at": null,
        "updated_at": null,
        "received_donation": null,
        "volunteer_id": null,
        "reservation_purpose": null,
        "reservation_coordinated": false,
        "unit": {},
        "customer": {},
        "car": {},
        "purpose": {},
        "departure": {},
        "volunteer": {},
        "requireTaxi": false,
        "taxiNotes": null
      },
      routeInstructionsData: null,
      finalDestination: {},
      departureStop: {},
      arrivalStop: {},
      selectedDepartureTime: null,
      selectedArrivalTime: null,
    }
  },

  computed: {},

  mounted() {
    const _this = this;

    _this.$vs.loading();

    let p1 = _this.getCustomers();


    Promise.all([p1]).then(() => {
      if (_this.reservationId) {
        _this.getIntermodalReservation().then(() => {

          _this.$vs.loading.close();

          if (_this.$route.params.createdViaDuplicateMethod) {
            Vue.$confirm({
              title: 'Rezervacija je bila ustvarjena preko funkcionalnosti \'Podvoji\'!',
              message: 'Datum in čas odhoda in prihoda sta bila nastavljena na trenutni datum in čas, polje \'Prostovoljec, ki bo opravil prevoz\' je bilo izpraznjeno. Prosim, vnesi pravilne podatke o rezervaciji in shrani spremembe!',
              button: {
                yes: 'OK',
              },
              callback: confirm => {

              }
            });
          }


        });
      } else {


        _this.$vs.loading.close();

        geoCoder = new google.maps.Geocoder();
        geoCoder.geocode({'address': this.$globalFunctions.getCurrentUnit().locationForContracts + ', Slovenija'}, function (results, status) {
          if (status === 'OK') {
            _this.center = results[0].geometry.location;

            if(_this.$route.params.intermodalReservationData) {
              _this.prefillDataFromConnectedIntermodalReservation();
            }

          } else {
            console.error('Geocode was not successful for the following reason: ' + status);
          }
        });


      }
    });
  },

  watch: {
    reservationId: {
      handler(val, oldVal) {
        console.log("CHANGE!!!!");
        console.log("val", val);
        this.reservationData = null;

        if (val) {
          this.getIntermodalReservation();
        }
      }
    }
  },

  methods: {
    async getCustomers() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'customers/unit/' + _this.$globalFunctions.getCurrentUnit().id)
        .then((res) => {
          _this.customersData = res.data.data;
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },
    async getIntermodalReservation() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'intermodal-reservations/' + _this.reservationId)
        .then((res) => {

          _this.reservationData = res.data.data;
          _this.finalDestination = res.data.data.finalDestination;

          if (res.data.data.type === 'FROM_HOME_TO_DESTINATION') {
            _this.reservationData.departure = res.data.data.departureStop;
            _this.reservationData.destination = res.data.data.arrivalStop;
          } else {
            _this.reservationData.departure = res.data.data.arrivalStop;
            _this.reservationData.destination = res.data.data.departureStop;
          }

          _this.customer = _.filter(_this.customersData, {id: res.data.data.customer.id})[0];
          _this.routeInstructionsData = JSON.parse(res.data.data.instructions);
          _this.reservationData.requireTaxi = !res.data.data.requireTaxi || res.data.data.requireTaxi == null ? 'false' : 'true'; // use strings for booleans !!!
          // _this.volunteer = _.filter(_this.volunteersData, {id: res.data.data.volunteer_id})[0];
          // _this.reservationPurpose = _.filter(_this.reservationPurposesData, {id: res.data.data.reservation_purpose})[0];
          // _this.car = _.filter(_this.carsData, {id: res.data.data.car_id})[0];
          _this.departureTime = res.data.data.departureTime;
          _this.arrivalTime = res.data.data.arrivalTime;


          if(res.data.data.type === 'FROM_HOME_TO_DESTINATION') {
            _this.arrivalTime = _this.moment(res.data.data.arrivalTime).format('HH:mm');
            _this.getArrivalTime();
          } else {
            _this.departureTime = _this.moment(res.data.data.departureTime).format('HH:mm');
            _this.getDepartureTime();
          }
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    saveReservation() {
      const _this = this;

      // let departureDate = _this.moment(_this.reservationData.departureTime).get('date');
      // let departureMonth = _this.moment(_this.reservationData.departureTime).get('month');
      // let departureYear = _this.moment(_this.reservationData.departureTime).get('year');
      // let departureHour = (_this.departureTime && _this.departureTime.toString().indexOf(':') > -1) ? _this.departureTime.split(':')[0] : _this.moment(_this.departureTime).format('HH');
      // let departureMinute = (_this.departureTime && _this.departureTime.toString().indexOf(':') > -1) ? _this.departureTime.split(':')[1] : _this.moment(_this.departureTime).format('mm');
      // let departureTime = _this.moment().set({
      //   'year': departureYear,
      //   'month': departureMonth,
      //   'date': departureDate,
      //   'hour': departureHour,
      //   'minute': departureMinute
      // }).valueOf();

      let departureTime = _this.selectedDepartureTime;
      if(_this.reservationData.type === 'FROM_HOME_TO_DESTINATION') {
        departureTime = null;
      }

      // let arrivalDate = _this.moment(_this.reservationData.departureTime).get('date');
      // let arrivalMonth = _this.moment(_this.reservationData.departureTime).get('month');
      // let arrivalYear = _this.moment(_this.reservationData.departureTime).get('year');
      // let arrivalHour = (_this.arrivalTime && _this.arrivalTime.toString().indexOf(':') > -1) ? _this.arrivalTime.split(':')[0] : _this.moment(_this.arrivalTime).format('HH');
      // let arrivalMinute = (_this.arrivalTime && _this.arrivalTime.toString().indexOf(':') > -1) ? _this.arrivalTime.split(':')[1] : _this.moment(_this.arrivalTime).format('mm');
      // let arrivalTime = _this.moment().set({
      //   'year': arrivalYear,
      //   'month': arrivalMonth,
      //   'date': arrivalDate,
      //   'hour': arrivalHour,
      //   'minute': arrivalMinute
      // }).valueOf();

      let arrivalTime = _this.selectedArrivalTime;
      if(_this.reservationData.type === 'FROM_DESTINATION_TO_HOME') {
        arrivalTime = null;
      }


      let data = {
        "type": _this.reservationData.type,
        "customer": _this.customer.id,
        "unit": _this.$globalFunctions.getCurrentUnit().id,
        "reservation": null,
        "arrivalTime": arrivalTime,
        "departureTime": departureTime,
        "notes": _this.reservationData.notes,
        "instructions": JSON.stringify(_this.routeInstructionsData),
        "connectedIntermodalReservation": _this.$route.params.intermodalReservationData ? _this.$route.params.intermodalReservationData.id : null,
        "finalDestination": _this.finalDestination,
        "departureStop": _this.departureStop,
        "arrivalStop": _this.arrivalStop,
        "requireTaxi": _this.reservationData.requireTaxi === 'true' ? true : false, // convert string to boolean
        "taxiNotes": _this.reservationData.taxiNotes
      }

      console.log("intermodal Reservation data", data);

      _this.$vs.loading();

      Vue.prototype.$http.post(Vue.prototype.$config.api.sopotnikiVPS + 'intermodal-reservations', data)
        .then((res) => {

          console.log("new intermodal reservation id:", res.data.data.id);
          if(_this.$route.params.intermodalReservationData) {
            let _data = {
              connectedIntermodalReservation: res.data.data.id
            };

            Vue.prototype.$http.put(Vue.prototype.$config.api.sopotnikiVPS + 'intermodal-reservations/' + _this.$route.params.intermodalReservationData.id, _data)
              .then((_res) => {

                console.log("_res", _res);
                _this.$vs.loading.close();
                _this.$router.push({name: "IntermodalReservations"});

              })
              .catch((error) => {
                console.log("error", error);
                _this.$vs.loading.close();
                return false
              });
          } else {
            console.log("res", res);
            _this.$vs.loading.close();
            _this.$router.push({name: "IntermodalReservations"});
          }

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    updateReservation() {
      const _this = this;

      // let departureDate = _this.moment(_this.reservationData.departureTime).get('date');
      // let departureMonth = _this.moment(_this.reservationData.departureTime).get('month');
      // let departureYear = _this.moment(_this.reservationData.departureTime).get('year');
      // let departureHour = (_this.departureTime && _this.departureTime.toString().indexOf(':') > -1) ? _this.departureTime.split(':')[0] : _this.moment(_this.departureTime).format('HH');
      // let departureMinute = (_this.departureTime && _this.departureTime.toString().indexOf(':') > -1) ? _this.departureTime.split(':')[1] : _this.moment(_this.departureTime).format('mm');
      // let departureTime = _this.moment().set({
      //   'year': departureYear,
      //   'month': departureMonth,
      //   'date': departureDate,
      //   'hour': departureHour,
      //   'minute': departureMinute
      // }).valueOf();

      let departureTime = _this.selectedDepartureTime;

      if(_this.reservationData.type === 'FROM_HOME_TO_DESTINATION') {
        departureTime = null;
      }

      // let arrivalDate = _this.moment(_this.reservationData.departureTime).get('date');
      // let arrivalMonth = _this.moment(_this.reservationData.departureTime).get('month');
      // let arrivalYear = _this.moment(_this.reservationData.departureTime).get('year');
      // let arrivalHour = (_this.arrivalTime && _this.arrivalTime.toString().indexOf(':') > -1) ? _this.arrivalTime.split(':')[0] : _this.moment(_this.arrivalTime).format('HH');
      // let arrivalMinute = (_this.arrivalTime && _this.arrivalTime.toString().indexOf(':') > -1) ? _this.arrivalTime.split(':')[1] : _this.moment(_this.arrivalTime).format('mm');
      // let arrivalTime = _this.moment().set({
      //   'year': arrivalYear,
      //   'month': arrivalMonth,
      //   'date': arrivalDate,
      //   'hour': arrivalHour,
      //   'minute': arrivalMinute
      // }).valueOf();
      let arrivalTime = _this.selectedArrivalTime;

      if(_this.reservationData.type === 'FROM_DESTINATION_TO_HOME') {
        arrivalTime = null;
      }


      let data = {
        "type": _this.reservationData.type,
        "customer": _this.customer.id,
        "unit": _this.$globalFunctions.getCurrentUnit().id,
        "reservation": null,
        "arrivalTime": arrivalTime,
        "departureTime": departureTime,
        "notes": _this.reservationData.notes,
        "instructions": JSON.stringify(_this.routeInstructionsData),
        "connectedIntermodalReservation": _this.$route.params.intermodalReservationData ? _this.$route.params.intermodalReservationData.id : null,
        "finalDestination": _this.finalDestination,
        "departureStop": _this.departureStop,
        "arrivalStop": _this.arrivalStop,
        "requireTaxi": _this.reservationData.requireTaxi === 'true' ? true : false, // convert string to boolean
        "taxiNotes": _this.reservationData.taxiNotes
      }

      console.log("intermodal Reservation data", data);

      _this.$vs.loading();

      Vue.prototype.$http.put(Vue.prototype.$config.api.sopotnikiVPS + 'intermodal-reservations/' + _this.reservationId, data)
        .then((res) => {

          console.log("new intermodal reservation id:", res.data.data.id);
          if(_this.$route.params.intermodalReservationData) {
            let _data = {
              connectedIntermodalReservation: res.data.data.id
            };

            Vue.prototype.$http.put(Vue.prototype.$config.api.sopotnikiVPS + 'intermodal-reservations/' + _this.$route.params.intermodalReservationData.id, _data)
              .then((_res) => {

                console.log("_res", _res);
                _this.$vs.loading.close();
                _this.$router.push({name: "IntermodalReservations"});

              })
              .catch((error) => {
                console.log("error", error);
                _this.$vs.loading.close();
                return false
              });

          } else {
            console.log("res", res);
            _this.$vs.loading.close();
            _this.$router.push({name: "IntermodalReservations"});
          }



        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    getAddressData(addressData, addressSection) {
      const _this = this;

      console.log("addressData", addressData);
      console.log("addressSection", addressSection);

      console.log("_this.finalDestination BEFORE: ", _this.finalDestination);
      document.getElementById(addressSection + "Address").value = addressData.formatted_address;
      _this.finalDestination.quickSearch = addressData.formatted_address;

      _.each(addressData.address_components, function (val) {
        if (val.types.includes('route')) {
          _this.finalDestination.route = val.long_name;
        } else if (val.types.includes('street_number')) {
          _this.finalDestination.streetNumber = val.long_name;
        } else if (val.types.includes('postal_town')) {
          _this.finalDestination.postalTown = val.long_name;
        } else if (val.types.includes('postal_code')) {
          _this.finalDestination.postalCode = val.long_name;
        } else if (val.types.includes('administrative_area_level_1')) {
          _this.finalDestination.municipality = val.long_name;
        } else if (val.types.includes('country')) {
          _this.finalDestination.country = val.long_name;
        }
      });

      if (_this.reservationData.type === 'FROM_HOME_TO_DESTINATION') {
        // _this.reservationData.departure = _.cloneDeep(_this.customer.addresses);
        _this.reservationData.destination = _.cloneDeep(_this.finalDestination);

      } else {
        _this.reservationData.departure = _.cloneDeep(_this.finalDestination);
        // _this.reservationData.destination = _.cloneDeep(_this.customer.addresses);

      }


      console.log("_this.finalDestination AFTER: ", _this.finalDestination);
      _this.$forceUpdate();
    },

    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["first_name", "last_name", "addresses.quick_search"],
        minMatchCharLength: 3,
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({item}) => item)
        : fuse.list;
    },

    fuseSearchName(options, search) {
      const fuse = new Fuse(options, {
        keys: ["name"],
        minMatchCharLength: 2,
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({item}) => item)
        : fuse.list;
    },

    customDateFormatter(date) {
      return this.moment(date).format('DD. MM. YYYY');
    },

    onDateChange() {
      const _this = this;

      _this.getDepartureTime();
      _this.getArrivalTime();

      // _this.$vs.loading();

      // _this.volunteer = null;
      // _this.reservationData.volunteer = null;
      //
      // _this.getVolunteers().then(() => {
      //   _this.getDailyReports().then(() => {
      //     _this.$vs.loading.close();
      //   });
      // });


    },

    onCustomerChange() {
      const _this = this;

      // _this.reservationData.departure = _.cloneDeep(_this.customer.addresses);
      // _this.reservationData.arrival = _.cloneDeep(_this.customer.addresses);

      if (_this.reservationData.type === 'FROM_HOME_TO_DESTINATION') {
        let departure = _.cloneDeep(_this.customer.addresses);
        // _this.reservationData.destination = {};
        _this.reservationData.departure = {
          "quickSearch": departure.quick_search,
          "route": departure.route,
          "streetNumber": departure.street_number,
          "postalCode": departure.postal_code,
          "postalTown": departure.postal_town,
          "municipality": departure.municipality,
          "country": departure.country,
          "addressName": departure.address_name
        }
      } else {
        // _this.reservationData.departure = {};
        let destination = _.cloneDeep(_this.customer.addresses);
        _this.reservationData.destination = {
          "quickSearch": destination.quick_search,
          "route": destination.route,
          "streetNumber": destination.street_number,
          "postalCode": destination.postal_code,
          "postalTown": destination.postal_town,
          "municipality": destination.municipality,
          "country": destination.country,
          "addressName": destination.address_name
        }
      }

      console.log(_this.customer);
    },

    beforeDeleteReservation() {
      const _this = this;

      Vue.$confirm({
        title: 'Ste prepričani, da želite izbrisati intermodalno rezervacijo uporabnika ' + _this.reservationData.customer.firstName + ' ' + _this.reservationData.customer.lastName + ' ?',
        message: 'Po potrditvi bo intermodalna rezervacija trajno izbrisana.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if (confirm) {
            _this.deleteReservation();
          }
        }
      });
    },

    onTypeChange() {
      const _this = this;

      if (_this.customer) {
        if (_this.reservationData.type === 'FROM_HOME_TO_DESTINATION') {
          _this.reservationData.departure = {
            "quickSearch": _this.customer.addresses.quick_search,
            "route": _this.customer.addresses.route,
            "streetNumber": _this.customer.addresses.street_number,
            "postalCode": _this.customer.addresses.postal_code,
            "postalTown": _this.customer.addresses.postal_town,
            "municipality": _this.customer.addresses.municipality,
            "country": _this.customer.addresses.country,
            "addressName": _this.customer.addresses.address_name
          };
          _this.reservationData.destination = {};

        } else {
          _this.reservationData.departure = {};

          _this.reservationData.destination = {
            "quickSearch": _this.customer.addresses.quick_search,
            "route": _this.customer.addresses.route,
            "streetNumber": _this.customer.addresses.street_number,
            "postalCode": _this.customer.addresses.postal_code,
            "postalTown": _this.customer.addresses.postal_town,
            "municipality": _this.customer.addresses.municipality,
            "country": _this.customer.addresses.country,
            "addressName": _this.customer.addresses.address_name
          }
          _this.finalDestination = _this.reservationData.destination;
        }
      }
    },

    deleteReservation() {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.delete(Vue.prototype.$config.api.sopotnikiVPS + 'intermodal-reservations/' + _this.reservationId)
        .then((res) => {

          _this.$router.go(-1);

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    getDepartureTime() {
      const _this = this;
      let departureTime = null;

      if (_this.reservationData.type === 'FROM_HOME_TO_DESTINATION') {
        return null;
      }

      // console.log("_this.departureTime", _this.departureTime);

      let departureDate = _this.moment(_this.reservationData.departureTime).get('date');
      let departureMonth = _this.moment(_this.reservationData.departureTime).get('month');
      let departureYear = _this.moment(_this.reservationData.departureTime).get('year');
      let departureHour = (_this.departureTime && _this.departureTime.toString().indexOf(':') > -1) ? _this.departureTime.split(':')[0] : _this.moment(_this.departureTime).format('HH');
      let departureMinute = (_this.departureTime && _this.departureTime.toString().indexOf(':') > -1) ? _this.departureTime.split(':')[1] : _this.moment(_this.departureTime).format('mm');
      departureTime = _this.moment().set({
        'year': departureYear,
        'month': departureMonth,
        'date': departureDate,
        'hour': departureHour,
        'minute': departureMinute
      }).startOf('minute').valueOf();


      _this.dynamicIndex = this.moment().valueOf();
      _this.selectedDepartureTime = departureTime;
      return departureTime;
    },

    getArrivalTime() {
      const _this = this;

      if (_this.reservationData.type === 'FROM_DESTINATION_TO_HOME') {
        return null;
      }

      // console.log("_this.arrivalTime", _this.arrivalTime);

      let arrivalTime = null;
      let arrivalDate = _this.moment(_this.reservationData.departureTime).get('date');
      let arrivalMonth = _this.moment(_this.reservationData.departureTime).get('month');
      let arrivalYear = _this.moment(_this.reservationData.departureTime).get('year');
      let arrivalHour = (_this.arrivalTime && _this.arrivalTime.toString().indexOf(':') > -1) ? _this.arrivalTime.split(':')[0] : _this.moment(_this.arrivalTime).format('HH');
      let arrivalMinute = (_this.arrivalTime && _this.arrivalTime.toString().indexOf(':') > -1) ? _this.arrivalTime.split(':')[1] : _this.moment(_this.arrivalTime).format('mm');
      arrivalTime = _this.moment().set({
        'year': arrivalYear,
        'month': arrivalMonth,
        'date': arrivalDate,
        'hour': arrivalHour,
        'minute': arrivalMinute
      }).startOf('minute').valueOf();

      _this.dynamicIndex = this.moment().valueOf();
      _this.selectedArrivalTime = arrivalTime;
      return arrivalTime;
    },

    routeInstructions(routeInstructionsData) {
      const _this = this;
      _this.routeInstructionsData = [];

      _.each(routeInstructionsData, function (val) {
        _this.routeInstructionsData.push(val.value);
      })

      console.log("routeInstructionsData", routeInstructionsData)
    },

    onRequiresTaxiChange(val) {
      const _this = this;

      _this.reservationData.requireTaxi = val;
      if(val === 'false') {
        _this.reservationData.taxiNotes = '';
      }
      _this.$forceUpdate();

    },

    prefillDataFromConnectedIntermodalReservation() {
      const _this = this;
      // _this.$vs.loading();

      const intermodalReservationData = _this.$route.params.intermodalReservationData;
      const instructions = JSON.parse(intermodalReservationData.instructions)[0];
      console.log("intermodalReservationData", intermodalReservationData);

      _this.customer = _.filter(_this.customersData, {id: intermodalReservationData.customer.id})[0];
      _this.reservationData.type = intermodalReservationData.type === 'FROM_HOME_TO_DESTINATION' ? 'FROM_DESTINATION_TO_HOME' : 'FROM_HOME_TO_DESTINATION';
      _this.reservationData.departureTime = _this.moment(intermodalReservationData.departureTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      _this.reservationData.arrivalTime = _this.moment(intermodalReservationData.arrivalTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

      if(intermodalReservationData.type === 'FROM_HOME_TO_DESTINATION') {
        _this.reservationData.departure = intermodalReservationData.arrivalStop;
        _this.departureStop = intermodalReservationData.arrivalStop;
        _this.reservationData.destination = intermodalReservationData.departureStop;
        _this.arrivalStop = intermodalReservationData.departureStop;

        _this.finalDestination = {
          id: _this.customer.addresses.id,
          quickSearch: _this.customer.addresses.quick_search,
          addressName: _this.customer.addresses.address_name,
          route: _this.customer.addresses.route,
          streetNumber: _this.customer.addresses.street_number,
          postalCode: _this.customer.addresses.postal_code,
          postalTown:_this.customer.addresses.postal_town,
          municipality: _this.customer.addresses.municipality,
          country: _this.customer.addresses.country
        };
      } else {
        _this.reservationData.departure = intermodalReservationData.departureStop;
        _this.reservationData.destination = intermodalReservationData.arrivalStop;
      }
    }

  }
}
</script>

